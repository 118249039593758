.btn {
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid #e2e5e9;
    color: #757a7f;
    width: 116px;
    height: 36px;
    transition: all linear .3s;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.btn-primary {
    font-size: 14px;
    background-color: #f37022;
    color: #fff;
    font-weight: 400;
}
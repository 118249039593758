.sample-request .form-group {
    margin-top: 16px;
    border: 1px solid #d1d5db;
    padding: 16px;
    border-radius: 8px;
  }
  .sample-request .field {
    margin-bottom: 16px;
  }
  .sample-request .field:last-child {
    margin-bottom: 0;
  }
  .sample-request .field label {
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    color: #777;
  }
  .dealer-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .dealer-info .field {
    margin-bottom: 16px;
    width: 48%;
  }
.error-field {
    border-color: red !important;
    color: red !important;
    outline: none;
  }
  .error-field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: red !important;
  }
  .error-field::-moz-placeholder { /* Firefox 19+ */
    color: red !important;
  }
  .error-field:-ms-input-placeholder { /* IE 10+ */
    color: red !important;
  }
  .error-field:-moz-placeholder { /* Firefox 18- */
    color: red !important;
  }
/* select {
  
  -moz-appearance: none;

  -webkit-appearance: none;
} */

/* For IE10 */
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;

  /* appearance: none; */
  /* padding: 2px 30px 2px 2px; */
  border: none;
  text-align: center;
}

select option {
  /* margin: 40px !important; */

  border: none;
  text-align: center;
}

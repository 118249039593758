@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "proxima-nova"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./font/Proxima\ Nova\ Font.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "proxima-nova", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100dvh; */
  /* width: 100dvw; */
  scroll-behavior: smooth;
  font-family: "proxima-nova", sans-serif;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  scroll-behavior: smooth;
  font-family: "proxima-nova", sans-serif;
}

* {
  font-family: "proxima-nova", sans-serif;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding-left: 10px;
  background: #f1f5f8;
  /* background: transparent; */
  cursor: pointer;
  border-radius: 10px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background: #bdc2c7;
  border-radius: 10px;
  transition: all linear 0.5s;
  border: 2px solid transparent;
  background-clip: padding-box;
  cursor: pointer;
}
::-webkit-scrollbar-hover,
::-webkit-scrollbar-thumb:hover {
  background: #ff971a;
  border: 3px solid #ff971a;
  border: 0;
  cursor: pointer;
}
.react-datepicker__input-container input:focus {
  /* outline: none; */
  /* border: 1.5px solid #ff971a; */
}

.react-datepicker__input-container input {
  /* width: 90px; */
  /* width: 100%; */
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px); */
  /* font-size: 1rem; */
  /* height: 50px; */

  font-weight: 400;
  /* line-height: 1.5; */
  color: #212529;
  /* background-color: #fff; */
  /* border: 1px solid #ced4da; */
  /* border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; */
  font-size: 14px;
}

.react-datepicker__input-container input::placeholder {
  font-size: 12px;
}

.react-datepicker-wrapper {
  /* width: 100px; */
}

.react-datepicker__input-container input:focus {
  outline: none;
  border: 1.5px solid #f37022;
}

.react-datepicker__input-container input {
  background: url("../../assets/calenderIcon.png") no-repeat right;
  background-position: 92%;
  width: 100%;
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px); */
  padding: 8px 8px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.react-datepicker__input-container input::placeholder {
  font-size: 13px;
}

input[type="checkbox"] {
  filter: sepia(100%) brightness(80%) hue-rotate(170deg) saturate(70%)
    contrast(300%);
}

/* .react-datepicker__input-container input {
  background: url("../../assets/calenderIcon.png") no-repeat right;
  background-position: 92%;
  background-size: 15px;
  width: 300px;
  height: 36px;
  padding: 0 10px;
  font-size: 14px;
} */

.create-lead-btn{
  border: 1px solid #f37022;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 4px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lead-form-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(50vw - 3rem);
  /* min-height: 50vh; */
}
.lead-item-container{
  display: flex;
  align-items: center;
  padding: 10px;
  /* height: 50px; */
  width: calc(25vw - 1.5rem);

}
.lead-item-container label{
  color:#8d7d7d ;
  display: inline-block;
  width: 100px;
}
.lead-item-container .lead-input{
  color:#8d7d7d ;
  display: inline-block;
  width: calc(25vw - 1.5rem - 120px);
  border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 38px;
    padding-left: 8px;
    font-size: 14px;
    color:#333
}
.lead-item-container .lead-input:hover{
  border: 1px solid #f37022;
}
.lead-item-container .lead-input:focus{
  border: 1px solid #f37022;
}
.lead-item-container .lead-input:focus-visible {
  outline-offset: 0px;
  outline: none;
}
.lead-item-container textarea{
  color:#8d7d7d ;
  display: inline-block;
  width: calc(50vw - 3rem - 120px);
  border: 1px solid #ced4da;
    border-radius: 0.25rem;
    min-height: 120px;
    padding-left: 8px;

}
.lead-item-container textarea:hover{
  border: 1px solid #f37022;
}
.lead-item-container textarea:focus{
  border: 1px solid #f37022;
}
.lead-item-container textarea:focus-visible {
  outline-offset: 0px;
  outline: none;
}
.error-text{
  font-size: 12px;
  color: red;
}
.custom-chip{
  font-size: 10px;
  border-radius: 20px;
  padding:4px 8px;
  width:47px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* margin-left: 8px; */
}

.custom-bg {
  position: relative;
}

.custom-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: linear-gradient(278.74deg, #4c89fa 3.28%, #2365e2 97.13%); */
  /* background-color: #2484c4; */
  background-color: rgb(236, 229, 229);
  z-index: -1;
}

.custom-bg::after {
  content: "";
  background-image: url("/src/assets/login-shape-right.svg"),
    url("/src/assets/login-shape-left.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  background-size: 270px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.15;
}
